import React, { useEffect, useState } from 'react';
import { useBackendData, useAddToBackend } from '../../api/BackendRequests';
import { useQueryClient } from '@tanstack/react-query';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import Checkbox from '../../Components/html/Checkbox';
import Error from '../../Pages/Error';
import { useParams, Link, Outlet, useLocation, Navigate } from 'react-router-dom';
import Spinner from '../../Components/html/Spinner';
import Button from "../../Components/html/Button";

function Clients(){
    const [search, setSearch] = useState('');
    const queryClient = useQueryClient();
    let [selectedClient, setSelectedClient] = useState(null);
    const [deleted, setDeleted] = useState(null);
    
    // data will contain: {results: [data], activeLicenseCount: int}
    const { isLoading, isSuccess, isError, error, data } = useBackendData('clients','/api/client', null, null, {retry: 0});
    const { ...mutation } = useAddToBackend('clients');
    
    // leo el id del url y extraigo del array de clients el objeto del id del url. También leo el pathname
    const { id } = useParams();
    const location = useLocation();

    // inicializo el search state para que al cargar clients no esté filtrada la tabla
    useEffect(() => {
        if(location.pathname === '/clients/' || location.pathname === '/clients'){
            setSearch('');
        }
    }, [location.pathname]);

    // defino las condiciones para cada child (pathname). Como en todos los children es requerido el "client",
    // con éste se controla el acceso a éstos desde la url, por lo que se valida en el momento del render.
    let client;
    if (location.pathname === '/clients/'+id && id && data) {
        client = data.results?.filter((client) => client.id === Number(id))[0];
    }

    if(location.pathname === '/clients/new' && data) {
        client = {
            activeLicenseCount: data.activeLicenseCount,
            active: Object.values(data.results).filter(n => n.active === 1).length-1 // resto 1 para el cliente de prueba que se entrega a cada customer
        };
    }

    // si existe data es porque existe el queryClient('clients). Si no existe data, invalidateQueries genera un error (loop!)
    if (location.pathname === '/clients/new/'+id && id && data) {
        queryClient.invalidateQueries('clients');
        
        // asigno el state que viene de CreateClient al context client para el Outlet
        client = location.state;
    }

//console.log(Object.values(data?.results).filter(n => n.active === 1).length);

    function activateClient(id, active) {
        //console.log(id, active);
        setSelectedClient(id);
        mutation.mutate({route: '/api/client/'+id, jsonArray: {active}, method: 'PATCH'},
            {
                onSuccess: () => {
                    queryClient.invalidateQueries('clients');
                    return true;
                }
            }
        );
    }

    function deleteClient(id) {
        mutation.mutate({route: '/api/client/'+id, jsonArray: null, method: 'delete'},
            {
                onSuccess: () => {
                    const deletedClient = queryClient.getQueryData(['clients']).results.find(obj => obj.id === id);
                    setDeleted({message: `The client ${deletedClient.name} has been deleted.`, class: 'alert-success'});
                    queryClient.invalidateQueries('clients');
                    return true;
                }
            }
        );
    }

    if(isError){
        // if the mutate returns error
        return <Error message={error.message} />
    }

    if(isLoading) {
        return <Spinner spin={{size: 10, message:'Loading your clients...'}} />
    }

    return <>
    {(location.pathname === '/clients' || location.pathname === '/clients/') && isSuccess ?
    <div>
        <div className='row pt-2'>
            <div className="col-sm-6">
                <h3>All clients</h3>
                <p>You are currently on a contract of <b>{data.activeLicenseCount}</b> licenses.</p>
            </div>
            <div className="col-sm-6">
                <div className="d-grid d-md-flex justify-content-md-end">
                    <Link to={`new`} className="btn btn-outline-primary" type="button">Add new client</Link>
                </div>
            </div>
            <Form>
                <InputGroup>
                    <Form.Control 
                    onChange = {(e) => setSearch(e.target.value)}
                    placeholder='Search clients by code or name'
                    />
                </InputGroup>
            </Form>
            
            {deleted && 
            <div className="row g-3">
                <div className="col-sm-12">
                    <div className={`alert col-lg-5 ${deleted.class}`} role="alert">{deleted.message}</div>
                </div>
            </div>}

            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Active</th>
                            {Object.values(data?.results).filter(n => n.deleteable === 1).length > 0 && <th>Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.results?.filter((item) => {
                            return search.toLowerCase() === '' ? item : (
                                item.code.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase())
                            )
                        })
                        .map((client) => (
                            <tr key={client.id}>
                                <td>
                                    <div className="d-flex position-relative">
                                        {<Link to={`${client.id}`} className={`btn btn-link btn-sm stretched-link ${!client.active && 'disabled'}`}>
                                            {client.name.charAt(0).toUpperCase() + client.name.slice(1)}
                                        </Link>}
                                    </div>
                                </td>
                                <td>{client.code}</td>
                                <td>
                                    {mutation.isError && client.id === selectedClient ? 
                                        <div className="invalid-feedback d-block">{mutation.error.message}</div> 
                                    : 
                                        <Checkbox id={client.id} active={client.active} activateId={activateClient}></Checkbox>
                                    }
                                </td>
                                <td>
                                    {
                                        (client.deleteable === 1) && <Button className='btn btn-danger btn-sm' onClick={() => deleteClient(client.id)}>Delete</Button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    </div>
    : (location.pathname === '/clients/'+id || location.pathname === '/clients/new' || location.pathname === '/clients/new/'+id) && client ? 
        <Outlet context={[client]} /> 
    : 
        <Error message="Error 404." />}
    </>
}

export default Clients;