import Menu from "../Components/Menus/Menu";
// import Breadcrumbs from "../Components/html/Breadcrumbs";
import { getCookie } from "../Utils/cookieHandler";
import { Outlet } from "react-router-dom";
import LanguageSelector from '../Components/html/LanguageSelector';

function AppLayout(){
    const cookie = getCookie('myclient_sid');
    //console.log('cookie', cookie);

    return <>
        <Menu menuItems={cookie.menu} />
        <div className="container app-content">
            {/* <Breadcrumbs /> */}
            <Outlet />
        </div>
        <section className="footer">
            <center>MyClient © {new Date().getFullYear()} | <LanguageSelector /></center>
        </section>
    </>
}

export default AppLayout;