import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useBackendData, useAddToBackend } from '../api/BackendRequests';
import Error from './Error';
import Button from '../Components/html/Button';
import Spinner from '../Components/html/Spinner';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
    let [email, setEmail] = useState('');
    const { t } = useTranslation('forgotPassword');

    function emailInputHandler(event){
        setEmail(event.target.value.replace(/\s/g,''));
    }

    const { mutate, isPending, data, isError, error } = useAddToBackend('users');

    function onSubmit(event){
        event.preventDefault();
        mutate({route: '/api/auth/forgotPassword', jsonArray: {email, username: event.target.id}, method: 'post'});
    }

    if(isPending) {
        return <Spinner spin={{size:10, message: 'sending_password'}} />
    }

    if(isError) {
        return <Error message={error?.message}></Error>
    }

    return <>
        <div className="mb-3">
            <h3>{t("title")}</h3>
        </div>

        {!data && 
        <div>
            <div className="mb-3">
                <p>{t("message")}</p>
            </div>

            <form onSubmit={onSubmit}>
                <div className='mb-3'>
                    <input type="email" className="form-control" placeholder={t("email")} onChange={emailInputHandler} value={email} required />
                </div>

                <div className='mb-0'>
                    <Button isPending={isPending}>{t("submit")}</Button>
                </div>
            </form>
        </div>
        }

        {data?.length > 1 && !data?.emailSent &&
        <div>
            <div className="mb-3">
                <p>{t("multiple_usernames")}</p>
            </div>

            <div className="mb-0">
                <div className="list-group col-lg-4">
                    {data.map((user,index) => (
                        <Button key={index} id={user.username} className={`list-group-item list-group-item-action`} onClick={onSubmit}>{user.username}</Button>
                    ))}
                </div>
            </div>
        </div>
        }

        {data?.emailSent === false &&
        <div>
            <div className="alert alert-warning" role="alert">{t("not_registered")}</div>
            <small><Link to="/" className='link-primary'>{t("go_back")}</Link></small>
        </div>
        }

        {data?.emailSent === true &&
        <div>
            <div className="alert alert-success" role="alert">{t("link_sent")}</div>
            <small><Link to="/" className='link-primary'>{t("go_back")}</Link></small>
        </div>
        }
    </>
}

export default ForgotPassword;