import React from 'react';
import  { Navigate } from 'react-router-dom';
import { useBackendData } from '../api/BackendRequests';
import { getCookie } from '../Utils/cookieHandler';

function Home() {

    const { isLoading, isSuccess, isError, data } = useBackendData('home','/api/auth/home', null, null, {retry: 0});

    if(isLoading){
        return <p>Loading...</p>
    }

    if(isError){
        return <Navigate to="/login" />
    }

    let cookies;
    if(isSuccess) {
        cookies = getCookie('myclient_sid');
        //console.log(cookies);
    }

    return <>
        Welcome to your dashboard, <strong>{cookies.username}</strong>!
    </>
}

export default Home;