import Cookies from 'js-cookie';

export const setCookie = (name, value, options={expires: 1}) => {

    const cookie_value = btoa(JSON.stringify(value)); // to decrypt use atob()
    //console.log(cookie_value);

    if(Cookies.set(name, cookie_value, options)) {
        return true;
    } else {
        return 'Error creating the cookie.';
    }
}

export const getCookie = (name) => {
    const cookie = Cookies.get(name);
    if (cookie) {
        try {
            return JSON.parse(atob(cookie));
        } catch (err) {
            Cookies.remove(name);
            return 'error';
        }
    }
}

export const removeCookie = (names=['myclient_sid']) => {
    try {
        names.forEach(name => {
            Cookies.remove(name);
        });
        //console.log('removing', names);

        return true;
    } catch (err) {
        return 'Error deleting the cookie.';
    }
}