import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import contentEN from '../locales/en/content.json';
import menuEN from '../locales/en/menu.json';
import loginEN from '../locales/en/login.json';
import forgotPasswordEN from '../locales/en/forgot_password.json';
import resetPasswordEN from '../locales/en/reset_password.json';
import spinnerEN from '../locales/en/spinner.json';
import ccontactEN from '../locales/en/ccontact.json';

import contentES from '../locales/es/content.json';
import menuES from '../locales/es/menu.json';
import loginES from '../locales/es/login.json';
import forgotPasswordES from '../locales/es/forgot_password.json';
import resetPasswordES from '../locales/es/reset_password.json';
import spinnerES from '../locales/es/spinner.json';
import ccontactES from '../locales/es/ccontact.json';

const resources = {
    en: {
        content: contentEN,
        menu: menuEN,
        forgotPassword: forgotPasswordEN,
        resetPassword: resetPasswordEN,
        login: loginEN,
        spinner: spinnerEN,
        ccontact: ccontactEN
    },
    es: {
        content: contentES,
        menu: menuES,
        forgotPassword: forgotPasswordES,
        resetPassword: resetPasswordES,
        login: loginES,
        spinner: spinnerES,
        ccontact: ccontactES
    }
};

i18n
.use(initReactI18next)
.init({
    resources,
    lng: "en", // default language
    debug: true,
    fallbackLng: "en", // use if selected language is not available
    interpolation: {
        escapeValue: false
    },
    ns: ["content","menu","resetPassword","login"]
});

export default i18n;