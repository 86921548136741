import React from 'react';
import { Link } from 'react-router-dom';
import download_light from '../../images/download_light.svg';

/**
 * This component needs to be worked on in the future so that it suits singe as well as multiple file download.
 * @param {Object} props 
 * @returns Button to download a file
 */
function CTableDownload(props) {
    // console.log(props);

    const onClickHandler = (param) => {
        props.clickHandler(param);
    };

    return <>
    
    <Link type="button" className='btn btn-light btn-sm' style={{'--bs-btn-padding-y': '.13rem', '--bs-btn-padding-x': '.3rem'}} 
    onClick={() => onClickHandler(props.data)} >
        <img src={download_light} alt='download' />
    </Link>
    
    </>
}

export default CTableDownload;