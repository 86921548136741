import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { getCookie, removeCookie } from '../../Utils/cookieHandler';
import LoginLayout from '../../Layouts/LoginLayout';
import Login from '../../Pages/Login';
import Home from '../../Pages/Home';
import Clients from '../../Features/Clients/Clients';
import Client from '../../Features/Clients/Client';
import ResetPassword from '../../Pages/ResetPassword';
import ForgotPassword from '../../Pages/ForgotPassword';
import Error from '../../Pages/Error';
import AppLayout from '../../Layouts/AppLayout';
import CreateClient from '../../Features/Clients/CreateClient';
import NewClient from '../../Features/Clients/NewClient';
import Users from '../../Pages/Users';
import Content from '../../Features/Content/Content';

const LayoutRouter = () => {
    const location = useLocation();
    let { pathname } = location;

    const cookie = getCookie('myclient_sid');

    //console.log(pathname);

    if((pathname.split('/')[1] === 'resetPassword' || pathname === '/forgotPassword') && !cookie){
        return <>
            <Routes>
                <Route element={<LoginLayout />}>
                    <Route path="/resetPassword/:token" element={<ResetPassword />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="*" element={<Error error_type={404}></Error>} />
                </Route>
            </Routes>
        </>
    } else if(pathname === '/login' || pathname === '/logout' || !cookie || cookie === 'error'){
        const logoutMessage = pathname === '/logout' || cookie === 'error' ? 'The session has ended, please login again.' : null;
        const noCookie = removeCookie();

        return <> {noCookie && 
            <Routes>
                <Route element={<LoginLayout />}>
                    <Route path="*" element={<Login logout={logoutMessage} />} />
                </Route>
            </Routes>
        }
        </>
    } else {
        return <>
        <Routes>
            <Route element={<AppLayout />}>
                <Route index element={<Home />} />
                <Route path="/clients" element={<Clients />}>
                    <Route path=":id" element={<Client />} />
                    <Route path="new" element={<CreateClient />} />
                    <Route path="new/:id" element={<NewClient />} />
                    <Route path="*" element={<Error />} />
                </Route>
                <Route path="/content/:id" element={<Content />} />
                <Route path="/error" element={<Error />} />
                <Route path="*" element={<Error code={404}></Error>} />
            </Route>
        </Routes>
        </>
    }
}

export default LayoutRouter;