import React, { useState, useEffect } from 'react';
import Button from '../../Components/html/Button';
import { useAddToBackend } from '../../api/BackendRequests';
import { useQueryClient } from '@tanstack/react-query';
import Error from './../../Pages/Error';
import Spinner from '../../Components/html/Spinner';

function CreateUser(props){
    let [email, setEmail] = useState('');

    function emailInputHandler(event){
        setEmail(event.target.value.replace(/\s/g,''));
    }

    const queryClient = useQueryClient();
    const { mutate, isPending, isSuccess, data, isError } = useAddToBackend('users');

    function onSubmit(event){

        event.preventDefault();
        const user = {
            email: email,
            client_id: props.client_id,
            role: 'Basic'
        }
        
        mutate({route: '/api/user', jsonArray: user, method: 'post'},
            {
                onSuccess: (data) => props.userAdded({message: `The username ${data.username} has been added successfully.`, class: 'alert-success'})
            }
        );
    }

    // Posibilidad de mover el contenido de este useEffect al "onSuccess" del mutate
    useEffect(() => {
        if(isSuccess && data){
            //console.log('data: ',data);
            //console.log('users 1: ', queryClient.getQueryData(['users']));

            queryClient.setQueryData(['users'], (oldData) => {
                return [...oldData, data];
            });

            // invalido clients para que la tabla de MyClients refresque el botón delete.
            queryClient.invalidateQueries('clients');

            //console.log('users 2: ', queryClient.getQueryData(['users']));
            setEmail('');
        }
    }, [isSuccess, data, queryClient]);

    if(isError){
        // if the mutation returns error
        return <Error></Error>
    }

    if(isPending){
        // return <small><img src={logo} className="App-logo" alt="logo" /> Creating a new user...</small>
        return <Spinner spin={{size:10, message:'Creating the new user...'}} />
    }

    return <>
        {
            props.countBadge.count === 0 ? (
                <div className="row g-3">
                    <div className="col-sm-12">
                        <div className="alert alert-info col-lg-5" role="alert">The maximum number of active users has been reached.</div>
                    </div>
                </div>
            ) : (
                <form onSubmit={onSubmit}>
                <div className="row g-3">
                    <div className="col-lg-4">
                        <input type="email" className="form-control" placeholder="Email" onChange={emailInputHandler} value={email} required />
                    </div>

                    <div className="col-sm-6">
                        <Button isPending={isPending}>Create User</Button>
                    </div>
                </div>
                </form>
            )
        }
    </>
}

export default CreateUser;