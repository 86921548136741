
/**
 * This function receives a value and a data configuration. It returns the formatted value, based on the config.
 * The data_type element from the config argument could have the following values, which corresponod to the "style"
 * property of the toLocaleString function options (these are the 3 types that can be returned; 'decimal' and 'currency' 
 * depend on the locale):
 * {
 *   text -> does not exist as an option
 *   decimal -> exists and formats a number with decimals and thousand separator, without currency symbol
 *   currency -> exists and formats the same as decimal with currency symbol
 * }
 * 
 * The json config file on each component_definitions must contain the 'locale' and 'currency' values in the header (component),
 * since these 2 values should be the same for the whole component (table, object). However, the 'digits' and 'data_type' 
 * configurations must be set for each field in the json file.
 */
export const format = (value, config) => {
    if(value === undefined || config.data_type === 'text' || config.data_type === 'date') {
        return value;
    }
    const defaults = {locale: 'en-US', digits: 2, data_type: 'decimal', currency: 'USD'};
    config = {...defaults, ...config};
    
    return value.toLocaleString(config.locale, {minimumFractionDigits: 2, style: config.data_type, currency: config.currency});
};