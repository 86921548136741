function Error(props){

    let error_message = '';

    if(props.code) {
        switch(props.code){
            case 404:
                error_message = 'The page you are trying to access does not exist.';
                break;
            default:
                error_message = 'Internal error server.';
                break;
        }
    } else if (props.message) {
        error_message = props.message;
    } else {
        error_message = 'Internal error server.';
    }

    return <div className="alert alert-danger" role="alert">{error_message}</div>
}

export default Error;