import React from 'react';
import { Table } from 'react-bootstrap';
import { format } from '../../Utils/formatFunctions';

/**
 * This basic component receives 2 objects that are required to build the table:
 * config: Array [{label: 'Test 1', api_name: 'test_1', properties: {location: 'OpportunityLineItems.records', data_type: 'text', searchable: true, visible_on_full: true, visible_on_summary: false} }]
 * data: Array [{id: 'xyz', Name: 'GenPoint Lab', Quantity: 5.67, Price: 1790.52}]
 * @param {Objects {config, data}} props 
 * @returns 
 */
function CSimpleTable(props) {
    // console.log(props);

    return <>
        <Table className='table-hover table-sm'>
            <thead>
                <tr>
                    {props.config.map((item,index) => (
                        <th key={item.api_name+index} className=''>
                            {item.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.data.map((item,index) => (
                    <tr key={index} className=''>
                        {props.config.map(field => (
                            <td key={field.api_name} className={`simple-table`}>
                                {format(item[field.api_name],{locale: props.locale, data_type: field.properties.data_type, currency: props.currency})}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    </>
}

export default CSimpleTable;