import React, { useEffect, useState } from 'react';

function Checkbox(props){

    let [isActive, setIsActive] = useState(props.active);

    function onChangeHandler(event){
        setIsActive(event.target.checked);
        props.activateId(props.id, event.target.checked);
    }

    useEffect(() => {
        setIsActive(props.active)
    }, [props.active]);

    //console.log();

    return <div className="form-check form-switch"><input className="form-check-input" type="checkbox" checked={isActive} onChange={onChangeHandler} disabled={props.disable}/></div>
}

export default Checkbox;