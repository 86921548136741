import React, {useState} from "react";
import i18n from '../../i18n/index';
import { Link } from "react-router-dom";

const LanguageSelector = () => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        //console.log(e);
        i18n.changeLanguage(e); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e);
    }
    
    return <>
        <React.Fragment key='lang'>
            {selectedLanguage === 'en' ? <Link onClick={() => chooseLanguage('es')}>Español</Link> : <Link onClick={() => chooseLanguage('en')}>English</Link>}
        </React.Fragment>
    </>
};

export default LanguageSelector;