import React from 'react';
import CSimpleTable from './CSimpleTable';
import { getLineItems } from '../../Utils/searchFunctions';
import { format } from '../../Utils/formatFunctions';

function CTableFull(props) {
    // console.log(props);
    
    const config = props.config.filter(item => {return item.properties.location !== 'header' && item.properties.location !== 'footer'});
    const line_items = getLineItems(config[0].properties.location, props.data)

    return <div className=''>
        <div className="card bg-light mt-2 mb-4">
            <div className="card-body ctable-full">
                {props.config.filter(item => {return item.properties.location === 'header'})
                .map(field => (
                    // props.data[field.api_name] !== undefined && <div key={field.api_name}><b>{field.label}: </b></div>
                    <div className='row' key={field.api_name}>
                        {props.data[field.api_name] !== undefined && <>
                        <div className='col-sm-2'><b>{field.label}:</b> </div>
                        <div className='col-sm-2 d-flex justify-content-end'>
                            {format(props.data[field.api_name],{locale: props.locale, data_type: field.properties.data_type, currency: props.currency})}
                        </div></>}
                    </div>
                ))}
                <br />
                <CSimpleTable config={config} data={line_items} locale={props.locale} currency={props.currency}  />

                {props.config.filter(item => {return item.properties.location === 'footer'})
                .map(field => (
                    <div className='row' key={field.api_name}>
                        {props.data[field.api_name] !== undefined && <>
                        <div className='col-sm-10 d-flex justify-content-end'><b>{field.label}:</b> </div> 
                        <div className='col-sm-2 d-flex justify-content-end'>
                            {format(props.data[field.api_name],{locale: props.locale, data_type: field.properties.data_type, currency: props.currency})}
                        </div>
                        </>}
                    </div>
                ))}
            </div>
        </div>
    </div>
}

export default CTableFull;