import React from "react";
//import logo from '../../images/my_client.svg';
//import logo from '../../images/logo_completo_transparent_v2.png';
import logo from '../../images/logo_cuadrado_small_v1.png';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

function Menu(props){
    // This menu will be dynamic and will load whatever is agreed with the customer to show to their clients.
    const location = useLocation();
    const { pathname } = location;

    return (
        <Navbar collapseOnSelect className="navbar fixed-top navbar-expand-lg navbar-light bg-navbar-color py-0" expand="false" bg="" variant="light">
            <div className="container-fluid">
                <Navbar.Brand className="navbar-brand" as={Link} to ="/"><img src={logo} alt="logo" className="d-inline-block align-text-top logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='navbar-nav ms-auto' activeKey={pathname}>
                        {props.menuItems?.map(item => 
                            ('submenu' in item) ? 
                                (
                                <NavDropdown title={item.label} key={item.label} active={item.submenu.find(({ to }) => to === pathname)}>
                                    {item.submenu.map(submenu1 =>
                                        <NavDropdown.Item className="nav-link" reloadDocument href={submenu1.to} key={submenu1.label} active={submenu1.to===pathname}>{submenu1.label}</NavDropdown.Item>
                                    )}
                                </NavDropdown>
                                )
                            :
                                (
                                <NavLink className="nav-link" to={item.to} key={item.label}>{/*<span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">*/}{item.label}{/*</span>*/}</NavLink>
                                )
                        )}
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}

export default Menu;