import React from 'react';

function TitleWithPill(props) {
    return <>
    <div className="row g-3">
        <div className="col-sm-12 d-flex flex-row gap-2">
            <h3>{props.children}</h3>
            <div><span className={`badge rounded-pill ${props.class} align-items-top`}>Free: {props.count}</span></div>
        </div>
    </div>
    </>
}

export default TitleWithPill;