import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useBackendData, useAddToBackend } from '../api/BackendRequests';
import Error from './Error';
import Button from '../Components/html/Button';
import Spinner from '../Components/html/Spinner';
import { Trans, useTranslation } from 'react-i18next';

function ResetPassword() {
    const { token } = useParams();
    const { isLoading, isError, error, data } = useBackendData('users','/api/auth/resetPassword/' + token, null, null);
    const { ...mutation } = useAddToBackend('users');
    const { t } = useTranslation('resetPassword');

    let [password, setPassword] = useState('');
    let [password2, setPassword2] = useState('');
    let [inputClasses, setInputClasses] = useState({passwordClass: 'form-control', password2Class: 'form-control', passwordError: null, password2Error: null});

    if(isLoading){
        return <Spinner spin={{size:10, message:'connecting'}} />
    }

    if(isError){
        return <Error message={error.message}></Error>
    }

    function passwordInputHandler(event){
        setPassword(event.target.value.replace(/\s/g,''));
    }

    function password2InputHandler(event){
        setPassword2(event.target.value.replace(/\s/g,''));
    }

    function submitForm(event){
        event.preventDefault();

        let passwordError = null;
        let password2Error = null;
        let passwordClass = 'form-control';
        let password2Class = 'form-control';

        if (!password || password.length < 5) {
            //passwordError = 'Password is too short';
            passwordError = t("error_short_password");
            passwordClass = 'form-control is-invalid';
        } else if (!password.match(/\d/) || !password.match(/[a-zA-Z]/g)){
            passwordError = t("error_password_policy");
            passwordClass = 'form-control is-invalid';
        } else if (password !== password2){
            password2Error = t("error_password_mismatch");
            password2Class = 'form-control is-invalid';
        } 

        setInputClasses({passwordClass, password2Class, passwordError, password2Error});
        
        if (!passwordError && !password2Error) {
            mutation.mutate({route: '/api/auth/changePassword/'+data.uid, jsonArray: {password}, method: 'PATCH'});
        }
    }

    if(mutation.isError){
        return <Error message={mutation.error?.message}></Error>
    }

    if(mutation.isSuccess) {
        //console.log(mutation.data);

        // loguear al usuario y luego -> home
        return <Navigate to="/" />
    }

    return <>
        <div className="mb-3">
            <h3>{t("title")}</h3>
        </div>
        <div className="mb-3">
            <p>{t("message")} <b>{data.username}</b></p>
            <Trans i18nKey="resetPassword:password_options" />
        </div>

        <div className='mb-0'>
            <form onSubmit={submitForm} noValidate>
                <div className="row p-1">
                    <input type="password" className={inputClasses.passwordClass} placeholder={t("new_password")} onChange={passwordInputHandler} value={password} />
                    <div className="invalid-feedback">{inputClasses.passwordError}</div>
                </div>
                <div className="row p-1">
                    <input type="password" className={inputClasses.password2Class} placeholder={t("confirm_password")} onChange={password2InputHandler} value={password2} />
                    <div className="invalid-feedback">{inputClasses.password2Error}</div>
                </div>
                <div className="row p-1"><Button>{t("submit")}</Button></div>
            </form>
        </div>
    </>
}

export default ResetPassword;