import { Link, Outlet } from "react-router-dom";
import logo from '../images/logo_completo_transparent_small_v2.png';
import LanguageSelector from '../Components/html/LanguageSelector';

function LoginLayout(){
    return <>
        <div className="container d-flex justify-content-center mt-1">
            <div className="flex-column col-lg-4">
                <div className="mb-4">
                    <Link to="/"><img src={logo} alt="logo" height="45px" /></Link>
                </div>

                <Outlet />
            </div>
            <section className="footer">
                <center>MyClient © {new Date().getFullYear()} | <LanguageSelector /></center>
            </section>
        </div>
    </>
}

export default LoginLayout;