import React, { useState } from 'react';
import  { Link, Navigate } from 'react-router-dom';
import { useAddToBackend } from '../api/BackendRequests';
import Button from '../Components/html/Button';
import { useTranslation } from 'react-i18next';

function Login(props){
    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    let [inputClasses, setInputClasses] = useState({usernameClass: 'form-control', passwordClass: 'form-control', usernameError: null, passwordError: null});
    const { t } = useTranslation('login');

    const { mutate, isLoading, isError, error, isSuccess, data } = useAddToBackend('login');

    if(isLoading){
        return <p className="text-left fs-3">Loading...</p>
    }

    if(isSuccess) {
        return data.authenticatedUser ? <Navigate to ="/" /> : <Navigate to="/error" />
    }

    function usernameInputHandler(event){
        setUsername(event.target.value.replace(/\s/g,''));
    }

    function passwordInputHandler(event){
        setPassword(event.target.value.replace(/\s/g,''));
    }

    function submitForm(event){
        event.preventDefault();

        let usernameError = null;
        let passwordError = null;
        let usernameClass = 'form-control';
        let passwordClass = 'form-control';

        if (!username || username.length <= 3) {
            //usernameError = 'Username is too short';
            usernameError = t("error_short_username");
            usernameClass = 'form-control is-invalid';
        } else if (!password || password.length < 5) {
            //passwordError = 'Password is too short';
            passwordError = t("error_short_password");
            passwordClass = 'form-control is-invalid';
        }

        setInputClasses({usernameClass,passwordClass,usernameError,passwordError});

        if(!usernameError && ! passwordError){
            mutate({route: '/api/auth/login', jsonArray: {username, password}, method: 'post'});
        }
    }

    return <>
        <form onSubmit={submitForm} noValidate>
            {isError ? 
            (<div className="mb-3"><div className={`alert alert-danger`} role="alert">{error.message}</div></div>) : 
            props.logout ? 
            (<div className="mb-3"><div className={`alert alert-warning`} role="alert">{props.logout}</div></div>) :
            null}

            <div className="card login">
                <div className="card-body login">
                    <div className='mb-2'>
                        <p>{t("message")}</p>
                    </div>

                    <div className='mb-2'>
                        <input type="text" className={inputClasses.usernameClass} placeholder={t("username")} onChange={usernameInputHandler} value={username} />
                        <div className="invalid-feedback">{inputClasses.usernameError}</div>
                    </div>

                    <div className='mb-1'>
                        <input type="password" className={inputClasses.passwordClass} placeholder={t("password")}  onChange={passwordInputHandler} value={password} />
                        <div className="invalid-feedback">{inputClasses.passwordError}</div>
                    </div>

                    <div className='mb-0'>
                        <small><Link to="/forgotPassword" className='link-primary'>{t("forgot")}</Link></small>
                        <div className='text-end'><Button className="btn btn-primary text-nowrap">{t("submit")}</Button></div>
                    </div>
                </div>
            </div>
        </form>
    </>
}

export default Login;