import React from 'react';
import logo from '../../images/logo_cuadrado_v2.png';
import { useTranslation } from 'react-i18next';

/**
 * @description This component returns the spinner based on certain parameters
 * @param {object} props It contains the spinner type and message: spinner{size: number, message: string}
 * @returns Spinner component
 */
function Spinner(props){
    const logoClass = 'logo_'+props.spin.size;
    const { t } = useTranslation('spinner');
    return <>
        <div className="row">
            <div className="col-lg-12">
                <img src={logo} className={`rounded mx-auto d-block App-logo ${logoClass}`} alt="logo" />
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12 text-center">
                {/* {props.spin.message} */}
                {t(props.spin.message)}
            </div>
        </div>
    </>
}

export default Spinner;