import React, { useEffect, useState } from 'react';
import Button from "../../Components/html/Button";
import Checkbox from '../../Components/html/Checkbox';
import { useAddToBackend } from '../../api/BackendRequests';
import { useQueryClient } from '@tanstack/react-query';
import Error from '../../Pages/Error';
import moment from 'moment';
import logo from '../../images/logo_cuadrado_v2.png';
import Spinner from '../../Components/html/Spinner';

function AllUsers(props){

    const queryClient = useQueryClient();

    //console.log('results: ',queryClient.getQueryData(['users']).find(obj => obj.id === 1463));
    //console.log(queryClient.getQueryData(['users']));

    const { mutate, isPending, isSuccess, data, isError, error } = useAddToBackend('users');
    let [selectedUser, setSelectedUser] = useState({id: null, message: ''});

    function deleteUser(id){
        mutate({route: '/api/user/' + id, jsonArray: null, method: 'delete'},
            {
                onSuccess: () => {
                    const deletedUser = queryClient.getQueryData(['users']).find(obj => obj.id === id);
                    props.userDeleted({message: `The user ${deletedUser.username} has been deleted.`, class: 'alert-success'});
                    return true;
                }
            }
        );
    }

    function activateUser(id, active){
        if (props.count >= 0){
            mutate({route: '/api/user/' + id, jsonArray: {active}, method: 'PATCH'}, // PATCH must be in capital letters
                {
                    onSuccess: () => {
                        const activeUser = queryClient.getQueryData(['users']).find(obj => obj.id === id);
                        const status = active ? 'activated' : 'deactivated';
                        props.userActive({message: `${activeUser.username} has been ${status}.`, class: 'alert-success'});
                        return true;
                    }
                }
            );
        }
    }

    function sendLink(id){
        setSelectedUser({id, message: 'Sending email...'});
        mutate({route: '/api/auth/sendPasswordLink/'+id, jsonArray: {id}, method: 'PATCH'},
            {
                onSuccess: () => {
                    const linkUser = queryClient.getQueryData(['users']).find(obj => obj.id === id);
                    props.linkSent({message: `A reset-password email has been sent to the user ${linkUser.username}.`, class: 'alert-success'});
                    return true;
                }
            }
        );
    }

    useEffect(() => {
        if(isSuccess && data){
            queryClient.invalidateQueries('users');
        }
    }, [isSuccess, data, queryClient]);

    if(isError || data?.errorCode){
        // if the mutate returns error
        console.error(error);
        return <Error code={data?.errorCode}></Error>
    }
//console.log(props.data);
    return <>
        {props.data && props.data.length > 0 && 
        <>
        {/* <hr/> */}
        <br/>
        <h3>All users</h3>
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Username</th>
                    <th scope="col">Last login</th>
                    <th scope="col">Active</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {
                    props.data?.map((user) => {
                        return (
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.username}</td>
                            <td>{user.last_login && moment(user.last_login).calendar()}</td>
                            <td><Checkbox id={user.id} active={user.active} activateId={activateUser} disable={!user.last_login || (props.count<=0 && !user.active)}></Checkbox></td>
                            {isPending && user.id === selectedUser.id && <td><Spinner spin={{size:5}} /></td>}
                            {(!isPending || (isPending && user.id !== selectedUser.id)) && 
                                <td>
                                    {(user.active || (!user.active && !user.last_login)) && <Button className="btn btn-secondary btn-sm" onClick={()=>sendLink(user.id)}>{user.active ? 'Reset password' : 'Resend link'}</Button>}
                                    {' '}
                                    {!user.last_login && <Button className='btn btn-danger btn-sm' onClick={() => deleteUser(user.id)}>Delete</Button>}
                                </td>
                            }
                        </tr>
                        )
                    })
                }
            </tbody>
        </table>
        </>
        }
    </>
}

export default AllUsers;