/**
 * Function that searches for a text value in the headers and details of a table item. It returns the item if matches
 * were found.
 * @param {object} ctable_item 
 * @param {object} searchable 
 * @param {text} search 
 * @returns object
 */
export const tableSearch = (ctable_item, searchable, search) => {

    let filtered_orders = [];

    // loop through the searchable fields
    for(var i=0; i<searchable.length; i++) {
        if(searchable[i].properties.location === 'header' || searchable[i].properties.location === 'footer') {
            // functions toLowerCase and includes cannot be applied to numbers, only to string values
            if(isNaN(ctable_item[searchable[i].api_name])) {
                filtered_orders.push(inText(ctable_item[searchable[i].api_name], search));
            } else {
                // it's a number
                filtered_orders.push(equalNumber(ctable_item[searchable[i].api_name], search));
            }
        } else {
            //implementar qué pasa si existiera order.lineItems
            let line_item = ctable_item;
            const location_arr = searchable[i].properties.location.split('.'); // Ejemplo: ['OpportunityLineItems', 'records']
            for(var j=0; j<location_arr.length; j++) {
                if(line_item[location_arr[j]] === undefined) {
                    break;
                }
                line_item = line_item[location_arr[j]];
            }

            let line_api_name = searchable[i].api_name;
            line_item.forEach(item => {
                if(isNaN(item[line_api_name])) {
                    filtered_orders.push(inText(item[line_api_name], search));
                } else {
                    filtered_orders.push(equalNumber(item[line_api_name], search));
                }
            });
        }
    }
    return filtered_orders.includes(true) ? ctable_item : null;
};

/**
 * Function that searches a text value in an object field. It returns true if the string is found or false otherwise.
 * @param {object field} field 
 * @param {search text} search 
 * @returns boolean
 */
const inText = (field, search) => {
    return field?.toLowerCase().includes(search) ? true : false;
};

/**
 * Function that compares for an exact match between a searching number and a value in an object field. It returns true if the numbers are equal, false otherwise.
 * @param {object field} field 
 * @param {search number} search 
 * @returns boolean
 */
const equalNumber = (field, search) => {
    return field.toString() === search ? true : false;
};

/**
 * This function extracts the "line items" array from an object data.
 * @param {string with single value or multiple separated by "."} location 
 * @param {object where the location values are elements} data 
 * @returns 
 */
export const getLineItems = (location, data) => {
    const location_arr = location.split('.'); // Ejemplo: ['OpportunityLineItems', 'records']
    let line_items = data;
    for(var j=0; j<location_arr.length; j++) {
        if(line_items[location_arr[j]] === undefined) {
            break;
        }
        line_items = line_items[location_arr[j]];
    }

    return line_items;
};