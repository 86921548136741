import React from 'react';
import Users from '../../Pages/Users';
import { useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';

function NewClient(){

    const [client] = useOutletContext();

    return <>
    <div className="row pb-3">
        <div className="col-sm-12">
            <h1>{client.name}</h1>
        </div>
    </div>

    <div className="row g-3">
        <div className="col-sm-12">
            {/* <div className="alert alert-success col-lg-5" role="alert">Well done! {client.name} has been added successfully.</div> */}
            <p>Well done! <b>{client.name}</b> has been added successfully. Proceed with their user creation below, or <Link to={`/clients/new`}>click here</Link> to add another client.</p>
        </div>
    </div>

    <Users client_id={client.id} />
    </>
}

export default NewClient;