import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LayoutRouter from './Components/Router/LayoutRouter';

function App() {

  const client = new QueryClient();

  return (
    <>
      <QueryClientProvider client={client}>
        <Router>
          <LayoutRouter />
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
