import React, {useState} from 'react';
import  { Navigate } from 'react-router-dom';
import AllUsers from "../Features/Users/AllUsers";
import CreateUser from "../Features/Users/CreateUser";
import { useBackendData } from '../api/BackendRequests';
import TitleWithPill from '../Components/html/TitleWithPill';

function Users(props){

    const client_id = props.client_id; // viene del menú

    const { isLoading, isSuccess, isError, data } = useBackendData('users','/api/user/client/'+client_id, null, null, {retry: 0});
    let [message, setMessage] = useState({message: '', class: ''});

    if(isLoading){
        return <p className="text-left fs-3">Loading...</p>
    }

    // mejorar el errorHandling -> poner toda la página en error, no solo el AllUsers component
    if(isError){
        // if the useQuery returns error
        return <Navigate to="/login" state={{logout: true}} />
    }

    let countArray = { count: 0, countClass:'bg-info'};
    if( isSuccess ){
        if(data) {
            countArray.count = 5 - Object.keys(data).filter(function(key){
                return data[key].active === 1 || data[key].last_login === null;
            }).length;

            countArray.countClass = countArray.count === 0 ? 'bg-danger' : 'bg-info';
        }
    }

    function userAdded(messageOnCreate){
        //console.log(messageOnCreate);
        setMessage(messageOnCreate);
    }

    function userDeleted(messageOnDelete){
        //console.log('deleted: '+username);
        setMessage(messageOnDelete);
    }

    function userActive(messageOnActive){
        //console.log('active user: '+username, 'active/inactive: '+active);
        setMessage(messageOnActive);
    }

    function linkSent(messageOnLinkSent){
        setMessage(messageOnLinkSent);
    }

    return <>
        <div className='row g-3'>
            <TitleWithPill class={countArray.countClass} count={countArray.count}>Add new user</TitleWithPill>
        </div>

        {message.message && 
        <div className="row g-3">
            <div className="col-sm-12">
                <div className={`alert col-lg-5 ${message.class}`} role="alert">{message.message}</div>
            </div>
        </div>}

        <CreateUser countBadge={countArray} userAdded={userAdded} client_id={client_id} />
        <br/>
        <AllUsers data={data} count={countArray.count} userDeleted={userDeleted} userActive={userActive} linkSent={linkSent} />
    </>

}

export default Users;