import { useQuery, useMutation } from '@tanstack/react-query';

//export const fetchData = async ({route, jsonArray, method}) => {
    export const fetchData = async ({route, jsonArray, method}) => {
    let options= null;
    if (jsonArray) {
        options = {
            method,
            credentials: 'include',
            body: JSON.stringify(jsonArray),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        };
    }

    if (method === 'delete'){
        options = { method };
    }

    const res = await fetch(route, options);
    if (!res.ok) {
        //throw new Error('Network response in the fetch function (./api/BackendRequests.js) was not OK');
        // El error desde backend está en una promise, se captura de la siguiente forma:
        ///////////// activado para el authController.userLogin; si se dañó algo, rollback y revisar el login.
        const err = await res.text();
        //console.log(JSON.parse(err));
        throw new Error(JSON.parse(err).message);
    }

    const data = await res.json();
    return data.results;
};

/* This function is called from the components to fetch data from the database. It expects the following params: */
/* (key, route, jsonArray, method) */
/* key (string): name of the queryKey. Example: 'users' */
/* route (string): url to be called in the fetch function. It must include the id as well. Example: '/user/customer/40' */
/* jsonArray: null. Only used for insert/update */
/* method: null. Only used for insert/update */
export const useBackendData = (key,route,jsonArray,method, options = {retry: 3, enabled: true}) => {
    // console.log(options);
return useQuery({
        queryKey: [key],
        queryFn: () => fetchData({route, jsonArray, method}),
        ...options
    });
};

/* This function is called from the components to insert data from the database. It expects the following params: */
/* (key, route, jsonArray, method) */
/* key (string): name of the queryKey. Example: 'users' */
/* route (string): url to be called in the fetch function. It must include the id as well. Example: '/user/customer/40' */
/* jsonArray: data to be inserted */
/* method: post */
export const useAddToBackend = (key) => {
    return useMutation({
        //mutationFn: ({route,jsonArray,method,token}) => fetchData({route,jsonArray,method}),
        mutationFn: ({route,jsonArray,method,type}) => fetchData({route,jsonArray,method},type),
        mutationKey: [key],
        onSuccess: (data) => {
            // console.log('successfully added: ');
            // console.log(data);
            return data;
        },
        onError: (err) => {
            console.log('There has been an error executing the mutation.\n\n', err.message)
        }
    });
};